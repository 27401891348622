import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src045276070/src/variametrix-site-2021/src/templates/benefit.js";
export const query = graphql`
  {
    icons: file(name: { eq: "iconset--data" }) {
      publicURL
    }
    banner: file(name: { eq: "banner--concrete-data" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64 
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BenefitBlurb = makeShortcode("BenefitBlurb");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BenefitBlurb title="Charts that actually tell you something" icon={props.data.icons.publicURL} iconOffset={0} mdxType="BenefitBlurb">
  <p>
    VariaMetrix is focused on making data more readable and usable. Beyond
    standard reporting, VariaMetrix easily lets you compare to previous and
    average data so you can see the effect of your campaigns over baselines.
  </p>
    </BenefitBlurb>
    <BenefitBlurb title="Multiple date ranges" icon={props.data.icons.publicURL} iconOffset={1} mdxType="BenefitBlurb">
  <p>
    The date view of any report can be made independent of the rest of the
    reports in a dashboard so you can compare the same report in different ways
    on one screen.
  </p>
    </BenefitBlurb>
    <BenefitBlurb title="Combined source and attribution" icon={props.data.icons.publicURL} iconOffset={2} mdxType="BenefitBlurb">
  <p>
    Measure more than just the overall results of your marketing efforts. See
    where your success is coming from so you can improve on what works and learn
    what doesn't.
  </p>
    </BenefitBlurb>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      